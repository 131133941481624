import React from "react";
import styles from "./index.module.scss";
import { DownCircleOutlined, UpCircleOutlined } from "@ant-design/icons";
import { Button } from "antd";

interface IProps {
    isOpen: boolean;
    setIsOpen: (x: boolean) => void;
}

const IncidentRegisterHeader: React.FC<IProps> = ({ isOpen, setIsOpen }) => {
    const toggleIsOpen = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className={styles.wrapper}>
            <h2 className={styles.header}>Зарегистрировать инцидент</h2>
            <Button
                type="text"
                icon={
                    isOpen ? (
                        <UpCircleOutlined
                            style={{ fontSize: "24px", color: "#ffffff", marginTop: "5px" }}
                        />
                    ) : (
                        <DownCircleOutlined
                            style={{ fontSize: "24px", color: "#ffffff", marginTop: "5px" }}
                        />
                    )
                }
                onClick={toggleIsOpen}
            />
        </div>
    );
};

export default IncidentRegisterHeader;
