import { State } from "../../types/state";
import { NameSpace } from "../root-reducer";

export const getIsLoading = (state: State) =>
  state[NameSpace.extraEvents].loading;
export const getExtraEventsEventId = (state: State) =>
  state[NameSpace.extraEvents].event_id;
export const getExtraEvents = (state: State) =>
  state[NameSpace.extraEvents].eventsToChoose;
export const getCalendar = (state: State) =>
  state[NameSpace.extraEvents].calendar;
