import { Spin } from "antd";
import React, { useEffect } from "react";
import EventName from "../../components/Common/EventName/EventName";
// import Calendar from "../../components/ExtraEvents/Calendar/Calendar";
import ExtraEvent from "../../components/ExtraEvents/ExtraEvent/ExtraEvent";
import { EXTRA_EVENTS_CALENDAR_YEAR } from "../../const";
import { useAppDispatch } from "../../customHooks/useAppDispatch";
import { useAppSelector } from "../../customHooks/useAppSelector";
import {
    getExtraEvents,
    getExtraEventsEventId,
    getIsLoading,
} from "../../store/extraEvents/selector";
import { setExtraEventsEventId } from "../../store/extraEvents/slice";
import { getCalendarAction } from "../../store/extraEvents/thunk";
import { TExtraEvent } from "../../store/extraEvents/types";
import styles from "./index.module.scss";

const ExtraEvents = () => {
    const dispatch = useAppDispatch();
    const loading = useAppSelector(getIsLoading);
    const eventId = useAppSelector(getExtraEventsEventId);
    const eventsToChoose = useAppSelector(getExtraEvents);

    useEffect(() => {
        if (eventId) {
            dispatch(
                getCalendarAction({
                    event_id: eventId,
                    year: EXTRA_EVENTS_CALENDAR_YEAR,
                })
            );
        }
    }, [eventId, dispatch]);

    return (
        <div className={styles.wrapper}>
            <h1>
                Дополнительные мероприятия{" "}
                <span>{loading ? <Spin /> : null}</span>
            </h1>
            <div className={styles.eventName}>
                <EventName
                    eventId={eventId}
                    setEventId={setExtraEventsEventId}
                />
            </div>
            <p>
                В 2024 году есть возможность провести{" "}
                {eventsToChoose.length === 1
                    ? "дополнительное мероприятие"
                    : "дополнительные мероприятия 1 января и 12 июня"}
                .<br /> Если вы будете проводить мероприятие, отметьте его галочкой и нажмите кнопку «Отправить».
            </p>
            {eventsToChoose.map((event: TExtraEvent, index: number) => (
                <ExtraEvent
                    event={event}
                    index={index}
                    key={event.event_date}
                />
            ))}

            {/* {eventId ? <Calendar /> : null} */}
        </div>
    );
};

export default ExtraEvents;
